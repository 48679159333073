import React from 'react';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import Logo from '../images/logo.svg';
import { KontaktQuery } from '../../types/graphql-types';

type Props = {
    data: KontaktQuery;
};

const P = styled.p`
    ${tw`mb-6 font-sm leading-relaxed relative z-10`}
`;

export default ({ data }: Props) => {
    return (
        <Layout siteTitle={data.site?.siteMetadata?.title || ''}>
            <SEO title={data.site?.siteMetadata?.title || ''} keywords={[`AGB`, `DFP`, `Kontakt`]} />
            <div className="bg-gradient-top pb-24 md:pb-34">
                <div className="container mx-auto pt-12 mb-24 md:mb-32 flex items-center justify-center md:justify-start z-10">
                    <Link to={`/`}>
                        <img src={Logo} alt="Logo" className="object-contain w-full h-full mb-0" />
                    </Link>
                </div>
                <div className="container mx-auto flex flex-col relative">
                    <h1 className="mb-4 text-3xl md:text-4xl font-bold leading-tight relative z-10">Kontakt</h1>
                    <P>
                        Herzlichen Dank für Ihr Interesse. <br />
                        Wir freuen uns auf Ihre Kontaktaufnahme.
                    </P>
                    <P>
                        swisspeers AG <br />
                        Zürcherstrasse 12 <br />
                        CH-8400 Winterthur
                    </P>
                    <P>
                        <a href="mailto:info@swisspeers.ch">info@swisspeers.ch</a> <br />
                        <a href="tel:41525115080">+41 52 511 50 80</a>
                    </P>
                    <P>CHE-292.634.550</P>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query Kontakt {
        site {
            siteMetadata {
                title
                description
                image
                siteUrl
            }
        }
    }
`;
